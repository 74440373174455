import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useRef, useState, useEffect } from "react";
import Cookies from 'js-cookie';


import "./fsmchatwindow.css";
import {
  lastBotResp,
  lastChatMesg,
  chatMesgState,
  chatHistPagiState
} from "../../../../recoil/atoms";
import {
  WEBSOCKET_ROOT,
  TOLOCALE_LOCALES,
  TOLOCALE_OPTIONS,
  EXPIRED_TOKEN,
  INVALID_TOKEN,
  INVALID_SESSION,
  NOT_AUTHENTICATED,
  WS_DB_ERR,
  WS_ALGO_ERR,
  WS_REDIS_ERR,
  WS_DB_CON_ERR,
  WS_REDIS_CON_ERR,
} from "../../../../utils/appConstants";
import FSMChatHeader from "./chatHeader";
import FSMChatFooter from "./chatFooter";
import FSMChatMessage from "./chatMessage";
import ChatSuggestion from "./chatSuggestion";
import { refreshTokens, getChatHistory } from "../../../../api/api";
import AuthenticationService from "../../../../utils/authenticationService";

const DividerWithText = ({ children }) => {
  return (
    <div className="divider-container">
      {/* <div className="divider-border" /> */}
      <span className="divider-content">{children}</span>
      {/* <div className="divider-border" /> */}
    </div>
  );
};

let botloader = false;
let wsBackendErr = "";
let showSuggestion = false;
let preventAPIScrollTop = false;

const FSMChatWindowIndex = () => {
  const navigate = useNavigate();

  const messagesEndRef = useRef(null);
  const [spinner, setSpinner] = useState({
    loading: false,
    loadingMore: false,
    scrollBottom: false,
  });
  const wsRef = useRef(null);
  const [wsReconnect, setWsReconnect] = useState(null);
  const [lastBotMsg, setLastBotMsg] = useRecoilState(lastBotResp);
  const [lastUserMsg, setLastUserMsg] = useRecoilState(lastChatMesg);
  const [messageList, setMessageList] = useRecoilState(chatMesgState);
  const [chatPagination, setChatPagination] = useRecoilState(chatHistPagiState);
  const [openChatHeader, setOpenChatHeader] = useState(
    chatPagination?.noRefresh || false
  );

  if (messageList?.data && Object.keys(messageList.data).length === 1) {
    let currentDate = new Date().toLocaleDateString(
      TOLOCALE_LOCALES,
      TOLOCALE_OPTIONS
    );
    if (messageList?.data.hasOwnProperty(currentDate)) {
      if (
        messageList.data[`${currentDate}`]?.length === 1 &&
        messageList.data[`${currentDate}`]?.[0]?.["msg_id"] === -1
      ) {
        showSuggestion = true;
      } else {
        showSuggestion = false;
      }
    }
  }

  // Function to clear all cookies
 const clearCookiesForDomains = (domains) => {
    const allCookies = Cookies.get(); // Get all cookies
    console.log('All cookies:- ', allCookies);
    // Loop through each domain and remove cookies
    domains.forEach(domain => {
      Object.keys(allCookies).forEach(cookie => {
        Cookies.remove(cookie, { domain, path: '/' });
        console.log(cookie, 'removed');
      });
    });
  };

  useEffect(() => {
    if (wsReconnect) {
      return;
    }
    // Only set up the websocket once
    if (!wsRef.current) {
      // Call the function to clear all cookies
      clearCookiesForDomains(['i2b-ai-analytics.cio.accenture.com', '.accenture.com']);
      const client = new WebSocket(WEBSOCKET_ROOT + `chat`);
      wsRef.current = client;
      // window.client = client;

      // websocket onopen event listener
      client.onopen = () => {
       
        // console.log("Websocket onopen");
        // retry last message when connection lost only when token is expired
        if (wsBackendErr === EXPIRED_TOKEN && lastUserMsg) {
          handleSendUserMessage(lastUserMsg);
        }
        wsBackendErr = "";
      };

      // websocket onerror event listener
      client.onerror = (error) => {
        // Cookies.remove('session_uuid');
        // Cookies.remove('xauth_access_token');
        // Cookies.remove('xauth_refresh_token');
        console.log("WebSocket onerror", error);
      };

      // websocket onclose event listener
      client.onclose = async (event) => {
        console.log("WebSocket onclose", event);
        wsBackendErr = event?.reason?.toLowerCase() || "";
        if (event?.reason.toLowerCase() === EXPIRED_TOKEN) {
          // refresh token
          const generateNewTokens = await refreshTokens();
        } else if (
          event?.reason.toLowerCase() === WS_DB_ERR ||
          event?.reason.toLowerCase() === WS_ALGO_ERR ||
          event?.reason.toLowerCase() === WS_REDIS_ERR
        ) {
          botloader = false;
        } else if (
          event?.reason.toLowerCase() === INVALID_TOKEN ||
          event?.reason.toLowerCase() === INVALID_SESSION ||
          event?.reason.toLowerCase() === NOT_AUTHENTICATED ||
          event?.reason.toLowerCase() === WS_DB_CON_ERR ||
          event?.reason.toLowerCase() === WS_REDIS_CON_ERR
        ) {
          AuthenticationService.clearLocalStorageAttributes();
          botloader = false;
          navigate("/");
          return;
        } else if (event?.code === 1006 && event?.reason === "") {
          console.log("Else if code, reason:- ", event?.code, event?.reason);
        } else {
          console.log("Else case:- ", event?.reason);
        }
        if (wsRef.current) {
          // Connection failed
          // console.log("WebSocket closed by server");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          // console.log("WebSocket closed by app component unmount");
          return;
        }
        if (wsReconnect) {
          return;
        }
        // console.log("WebSocket closed");
        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWsReconnect(true);
        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => {
          setWsReconnect(null);
        }, 2000);
      };

      // websocket onmessage event listener
      client.onmessage = (event) => {
        if (event?.data) {
          let message = JSON.parse(event.data);
          // console.log("Websocket onmessage", message);
          let currentDate = new Date().toLocaleDateString(
            TOLOCALE_LOCALES,
            TOLOCALE_OPTIONS
          );
          botloader = false;
          setLastBotMsg(message);
          setMessageList((_messages) => {
            _messages.data[`${currentDate}`] = [
              ..._messages.data[`${currentDate}`],
              message,
            ];
            return { ..._messages };
          });
          window.setTimeout(scrollingBottom, 0);
        }
      };

      return () => {
        // console.log("Cleanup");
        // Dereference, so it will set up next time
       
        wsRef.current = null;
        client.close();
      };
    }
  }, [wsReconnect]);

  useEffect(() => {
    async function fetchMoreChat() {
      const resp = await getChatHistory(chatPagination);
      if (resp?.data && Object.keys(resp.data).length === 0) {
        if (messageList?.data && Object.keys(messageList.data).length === 0) {
          showSuggestion = true;
        } else {
          showSuggestion = false;
        }
      }
      if (resp?.data && Object.keys(resp.data).length > 0) {
        showSuggestion = false;
        let tempMsgList = structuredClone(messageList);
        if (preventAPIScrollTop) {
          // scroll to div id for unread msg
          const scrollElement = document.getElementById(
            `${tempMsgList.scrollDivId}`
          );
          scrollElement?.scrollIntoView({
            block: "start",
            inline: "start",
            behavior: "smooth",
          });
        }
        Object.keys(resp.data).forEach((dat) => {
          if (tempMsgList?.data.hasOwnProperty(dat)) {
            let tempCurrentDateMsgList = [
              ...resp.data[`${dat}`],
              ...tempMsgList.data[`${dat}`],
            ];
            tempMsgList.data[`${dat}`] = tempCurrentDateMsgList;
          } else {
            tempMsgList.data[`${dat}`] = resp.data[`${dat}`];
          }
        });
        setMessageList({
          loadMore: true,
          wsSendMsg: null,
          data: tempMsgList.data,
          scrollDivId:
            tempMsgList.data[Object.keys(tempMsgList.data)[0]][0]["msg_id"],
        });
      } else {
        setMessageList({ ...messageList, loadMore: false });
      }
      setSpinner({
        loading: false,
        loadingMore: false,
        scrollBottom: !preventAPIScrollTop ? true : false,
      });
      // prevents multiple call to chatHistory API at scrollTop = 0
      preventAPIScrollTop &&
        setTimeout(function () {
          preventAPIScrollTop = false;
        }, 0);
    }
    if (!chatPagination?.noRefresh) {
      if (!chatPagination?.noRefresh && !preventAPIScrollTop) {
        setSpinner({ loading: true, loadingMore: false, scrollBottom: false });
      }
      if (chatPagination?.skip === -1) {
        setChatPagination({
          ...chatPagination,
          skip: chatPagination?.skip + 1,
        });
      } else {
        fetchMoreChat();
      }
    } else {
      // not working as showSuggestion is not setting to true
      // if (messageList?.data && Object.keys(messageList.data).length === 0) {
      //   showSuggestion = true;
      // }
    }
  }, [chatPagination]);

  useEffect(() => {
    if (messageList?.wsSendMsg) {
      showSuggestion = false;
      botloader = true;
      scrollingBottom();
      setLastUserMsg(messageList.wsSendMsg);
      handleSendUserMessage(messageList.wsSendMsg);
      setMessageList({ ...messageList, wsSendMsg: null });
    }
  }, [messageList]);

  // scroll to bottom on refresh
  useEffect(() => {
    if (spinner?.scrollBottom) {
      scrollingBottom();
      setSpinner({ ...spinner, scrollBottom: false });
    }
  }, [spinner]);

  const scrollingBottom = () => {
    const e = messagesEndRef;
    e.current?.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  // scroll to bottom on noRefresh
  useEffect(() => {
    if (chatPagination?.noRefresh) {
      scrollingBottom();
    }
  }, []);

  const handleSendUserMessage = (data) => {
    if (wsRef?.current?.readyState) {
      wsRef.current?.send(JSON.stringify(data));
    } else {
      // console.log("Websocket lost connection");
    }
  };

  const handleScroll = (e) => {
    let element = e?.target;
    if (
      element?.scrollTop === 0 &&
      messageList?.loadMore &&
      !preventAPIScrollTop
    ) {
      preventAPIScrollTop = true;
      setOpenChatHeader(false);
      setChatPagination({
        ...chatPagination,
        isScroll: true,
        noRefresh: false,
        skip: chatPagination?.skip + 1,
      });
      setSpinner({ loading: false, loadingMore: true, scrollBottom: false });
    }
  };

  return (
    <>
      <div className="fsm-chat-root">
        <div
          onScroll={handleScroll}
          className={`fsm-chat-content ${
            showSuggestion
              ? "fsm-chat-content-suggestion"
              : "fsm-chat-content-normal"
          } ${
            botloader ? "fsm-chat-content-botloader" : "fsm-chat-content-normal"
          }`}
        >
          {spinner?.loadingMore ? (
            <div className="load-more-loader">
              <FadeLoader
                width={5}
                radius={2}
                margin={2}
                height={15}
                color={"#4279f4"}
                loading={spinner?.loadingMore}
              />
            </div>
          ) : null}
          {spinner?.loading ? (
            <div className="load-loader">
              <FadeLoader
                width={5}
                radius={2}
                margin={2}
                height={15}
                color={"#4279f4"}
                loading={spinner?.loading}
              />
            </div>
          ) : (
            <>
              {openChatHeader ? (
                <div className="fsm-chat-header">
                  <FSMChatHeader
                    chatPagination={chatPagination}
                    setChatPagination={setChatPagination}
                    setOpenChatHeader={setOpenChatHeader}
                  />
                </div>
              ) : null}
              {messageList?.data &&
                Object.keys(messageList.data).length > 0 &&
                Object.keys(messageList.data)
                  .sort(function (a, b) {
                    return new Date(a) - new Date(b);
                  })
                  .map((dat, ind) => {
                    return (
                      <React.Fragment key={dat}>
                        <DividerWithText>
                          {dat ===
                          new Date().toLocaleDateString(
                            TOLOCALE_LOCALES,
                            TOLOCALE_OPTIONS
                          )
                            ? "Today"
                            : `${dat}`}
                        </DividerWithText>
                        {messageList.data[dat]?.length > 0 &&
                          messageList.data[dat].map((message) => (
                            <FSMChatMessage message={message} />
                          ))}
                      </React.Fragment>
                    );
                  })}
              <div ref={messagesEndRef} />
            </>
          )}
          {wsReconnect ? (
            <div className="refreshing-session-loader-overlay">
              <div className="refreshing-session-loader-text">
                <FadeLoader
                  width={5}
                  radius={2}
                  margin={2}
                  height={15}
                  color={"#4279f4"}
                  loading={wsReconnect}
                />
                <div style={{ color: "white" }}>Refreshing your session</div>
              </div>
            </div>
          ) : null}
        </div>
        <ChatSuggestion showSuggestion={showSuggestion} />
        {botloader ? (
          <div className="bot-loader-root">
            <div className="bot-loader-inner">
              <PulseLoader color={"#4183c5"} size={"10px"} margin={"3px"} />
            </div>
          </div>
        ) : null}
        <div className="fsm-chat-footer">
          <FSMChatFooter />
        </div>
      </div>
    </>
  );
};

export default FSMChatWindowIndex;
