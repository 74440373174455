import { atom } from "recoil";

import { LS_CHAT_MESSAGE } from "../utils/appConstants";
import AuthenticationService from "../utils/authenticationService";

const store = typeof window !== "undefined" ? window.localStorage : null;

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    if (store) {
      const savedValue = AuthenticationService.getLocalStorageChatMessage();
      if (savedValue != null) {
        setSelf(savedValue);
      }
    }
  };

export const chatMesgState = atom({
  key: "chatMesgState",
  default: {
    data: {},
    loadMore: false,
    wsSendMsg: null,
    scrollDivId: "",
  },
  effects: [localStorageEffect(LS_CHAT_MESSAGE)],
});

export const chatHistPagiState = atom({
  key: "chatHistPagiState",
  default: { skip: -1, limit: 50, noRefresh: false, isScroll: false },
});

export const lastChatMesg = atom({
  key: "lastChatMesg",
  default: null,
});

export const lastBotResp = atom({
  key: "lastBotResp",
  default: null,
});
